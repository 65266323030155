<template>
  <div class="loading-files">
    <div class="loading-files__details">
      <div class="loading-files__details--name text-subtitle-1">
        {{ fileName }}
      </div>
      <v-progress-linear
        v-model="value"
        color="primary"
        background-color="dark"
        background-opacity="0.16"
        rounded
        class="loading-files__details--progress"
      />
    </div>
    <div class="loading-files__actions">
      <v-btn color="blue" outlined @click="cancelFunction()">
        {{ $trans("cancel") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingFiles",
  props: {
    value: {
      type: Number,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
    },
    cancelFunction: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-files {
  display: flex;
  align-items: center;
  padding: 1rem;
  background: var(--v-snow_gray-base);
}

.loading-files__details {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-files__details--progress {
  margin: 0 1rem;
  margin-top: 4px;
}

.loading-files__actions {
  width: max-content;
}
</style>
